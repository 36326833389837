<template>
  <div>
    <div>
      <div class="">
        <ValidationObserver ref="formCreateAddon">
          <form>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="addon_id" type="text" field_name="Tillägstjänst ID" rules="required" />
              </div>
              <div class="w-full md:w-1/3 px-2 py-2">
                <BaseInput v-model="name" type="text" field_name="Namn" rules="required" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/2 px-2 py-2">
                <BaseTextarea rows="5" v-model="description" field_name="Beskrivning" rules="required" />
              </div>
              <div class="w-full md:w-1/2 px-2 py-2">
                <label class="text-gray-600 text-sm font-bold pb-2 font-serif">Bild<b class="text-red-500 ml-1">*</b></label>
                <input type="file" accept="image/*" ref="imageCreate" @change="fileChangeCreate()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
              </div>
            </div>

            <div class="mx-2 border-b border-secondary-400 my-5"></div>
            <div class="flex flex-wrap">
              <div class="w-full px-2">
                <div class="clearfix">
                  <button class="btn-blue float-right text-lg py-2 px-5" type="submit" @click.prevent="createAddon()">
                    Spara
                    <BaseIcon icon="arrow-right" class="ml-2" />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title() {
    return `Skapa tillägstjänst | SecurCloud`;
  },
  data() {
    return {
      addon_id: "",
      name: "",
      description: "",
      image: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    createAddon() {
      this.$refs.formCreateAddon.validate().then((success) => {
        if (!success) {
          return;
        }
        // check if image empty
        if (this.image == null) {
          this.handleError(null, this.$t("error_.the_image_required"));
          return;
        }

        let headers = {
          "Content-Type": "multipart/form-data",
          "X-Access-Token": this.$store.state.token,
        };

        var formData = new FormData();

        formData.append("addon_id", this.addon_id);
        formData.append("name", this.name);
        formData.append("description", this.description);
        formData.append("image", this.image);

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/addon`, formData, headers)
          .then(() => {
            // eslint-disable-line
            this.$router.push({ name: "addons" });
          })
          .catch((error) => {
            this.handleError(error, "Ett problem uppstod när skapa Tillägstjänst");
          });
      });
    },

    fileChangeCreate() {
      if (this.$refs.imageCreate.files.length > 0) this.image = this.$refs.imageCreate.files[0];
      else this.logo = null;
    },
  },

  created() {
    this.setPageTitle("Skapa tillägstjänst", "create_addon");
  },
};
</script>
